import { Component } from '@angular/core';
import { QrViewComponent } from '@app/components/qr-view/qr-view.component';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-app-tab',
  templateUrl: './app-tab.component.html',
  imports: [
    QrViewComponent,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./app-tab.component.scss'],
})
export class AppTabComponent {

  public isQRVisible = false;
  public qrSrc = '';

  showQRCode(qrSrc: string): void {
    this.isQRVisible = true;
    this.qrSrc = qrSrc;
  }

}
