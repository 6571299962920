import { AfterContentInit, Component, DestroyRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SafeUrl } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { InvoiceQrcodeImageService, RestaurantTableService } from '@core';

@Component({
  selector: 'app-invoice-tab',
  templateUrl: './invoice-tab.component.html',
  imports: [
    TranslateModule,
  ],
  styleUrls: ['./invoice-tab.component.scss'],
})
export class InvoiceTabComponent implements AfterContentInit {

  qrUrl: SafeUrl | null = null;
  tableName: string | null = null;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly invoiceQrcodeImageService: InvoiceQrcodeImageService,
  ) {}

  ngAfterContentInit(): void {
    this.restaurantTableService.table$.pipe(
      map(table => table?.tableName ?? null),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((tableName) => {
      this.tableName = tableName;
    });

    this.invoiceQrcodeImageService.file$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((grUrl) => {
      this.qrUrl = grUrl;
    });
  }
}
