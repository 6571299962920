<div class="content">
  <div class="content__image">
    <img src="/images/joePayBill.jpg" alt="">
    <div class="content__image__text">
      <h1>Thank you for being with us</h1>
      <p>We hope you enjoyed our cuisine, as we tried our best.</p>
    </div>
  </div>

  <div class="content__qr">
    <div class="head">Please scan the QR code to pay your bill.</div>
    @if (qrUrl) {
      <img class="qr_img" [src]="qrUrl" alt="">
    }
    <div class="description">
      <p class="number">{{ tableName }}</p>
    </div>
  </div>
</div>
