import { isNgServe } from '@core';

import { Environment } from './type';

export const environment: Environment = {
  name: 'Oongalee ExcuseMe - Original Joe\'s - DEV',
  machineName: 'original-joes.dev',
  production: true,
  serviceWorker: !isNgServe,
  debug: true,
  apiUrl: 'https://oongalee-exscuseme-srv-dev.r2.danavero.com',
  socketApi: 'wss://oongalee-exscuseme-srv-dev.r2.danavero.com',
  sentry: {
    dsn: '',
  },
  backgroundPlayer: null,
  backgroundAdPlayer: 'https://dwall-app-ad-player.netlify.app',
  backgroundAdPlayerColor: null,
  update: {
    checkStrategy: 'every-minute',
    activateStrategy: 'restart-when-idle',
  },
  featureToggle: {
    idleClickGoTo: ['/main/home'],
    withTabBar: true,
    withCallWaiterMain: false,
    withCallWaiterRequestBill: false,
    withCallWaiterAnotherRound: false,
    withCallWaiterOnOffline: false,
    withInvoice: false,
    withServiceCentre: false,
    tabBarAutoHide: true,
    idleDelay: 30,
    ambientLight: {
      callWaiter: null,
      callWaiterToPay: null,
      callWaiterToRepeat: null,
    },
  },
};
