import { homePageAnimations } from './home-page.animation';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { isNotNull, MenusService } from '@core';
import { HappyHoursComponent } from '@app/components/happy-hours/happy-hours.component';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { merge, of, shareReplay } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.scss'],
  imports: [
    HappyHoursComponent,
    RouterOutlet,
    TranslateModule,
  ],
  animations: [
    homePageAnimations,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeTabComponent {

  private readonly currentUrl$ = merge(
    of(this.router.url),
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.urlAfterRedirects),
    ),
  ).pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  private readonly isHomePage$ = this.currentUrl$.pipe(
    map((url) => url === '/main/home'),
    shareReplay(1),
  );

  public readonly isHomePage = toSignal(this.isHomePage$);

  constructor(
    private readonly router: Router,
    private readonly menusService: MenusService,
  ) {}

  public specialOfferOnClick(): void {
    this.menusService.getMenuForCurrentMedia().pipe(
      filter(isNotNull),
    ).subscribe(() => {
      this.router.navigate(['/main/home/special-offer']).then();
    });
  }

  public prepareRoute(outlet: RouterOutlet): unknown {
    return outlet?.activatedRouteData['animation'];
  }

}
