@let isMenuPage = pageIs().menu;
@let isInvoicePage = pageIs().invoice;
@let isAppPage = pageIs().app;
@let isGiftPage = pageIs().gift;

<div
  class="tab-bar tab-bar__with-bg"
  [class.hide]="hideTabBar()"
>
  <div class="tabs" coreActivitySection="TabBar">
    <button
      class="tab"
      [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
    >
      <div class="icon">
        <img [class.active]="isMenuPage" src="/images/menu.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isMenuPage">
        <span>{{ 'tabBar.menu' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isInvoicePage ? '/main/home' : '/main/invoice'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
    >
      <div class="icon">
        <img [class.active]="isInvoicePage" src="/images/invoice.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isInvoicePage">
        <span>{{ 'tabBar.invoice' | translate }}</span>
      </div>
    </button>

    <button
      class="tab logo"
      routerLink='/main/home'
      coreUserActivityClickTracking="PageClose"
    >
      <img class="logoImg" src="/images/logo-vector.svg" alt=""/>
    </button>

    <button
      class="tab"
      [routerLink]="isAppPage ? '/main/home' : '/main/app'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isAppPage ? 'AppPageClose' : 'AppPageOpen'"
    >
      <div class="icon">
        <img [class.active]="isAppPage" src="/images/app.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isAppPage">
        <span>{{ 'tabBar.app' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isGiftPage ? '/main/home' : '/main/gift'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isGiftPage ? 'GiftPageClose' : 'GiftPageOpen'"
    >
      <div class="icon">
        <img [class.active]="isGiftPage" src="/images/gift-card.svg" alt=""  />
      </div>
      <div class="label" [class.active]="isGiftPage">
        <span>{{ 'tabBar.gift' | translate }}</span>
      </div>
    </button>
  </div>
</div>

@if (hideTabBar()) {
  <core-interacts-present/>
}
